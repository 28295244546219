import { ExceptionList } from './exception-list';

export class DontShowExceptionList extends ExceptionList {
    public static exceptionMessageList(): string[] {
        return [
            'URL_INVALID',
            'INVITATION_EMAIL_IS_THE_SAME_AS_ACCOUNT',
            'EMAIL_ALREADY_INVITED_BY_CUSTOMER'
        ];
    }
}
