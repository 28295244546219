export const I18N_SEARCH_EN = {
    por: 'by',
    by: 'by',
    hoje: 'Today',
    ontem: 'Yesterday',
    date_format: 'MM.yy',
    novo: 'new',
    usuario_externo: 'External User',
    api: 'API'
};

export const I18N_SEARCH_PT = {
    por: 'por',
    by: 'pela',
    hoje: 'Hoje',
    ontem: 'Ontem',
    date_format: 'MM.yy',
    novo: 'novo',
    usuario_externo: 'Cliente',
    api: 'API'
};