export const I18N_STEP_INFLUENCER_EN = {
    desconhecido: 'Unaware',
    conhecido: 'Aware',
    interessado: 'Interested',
    engajado: 'Engaged',
    promotor: 'Advocate'
};

export const I18N_STEP_INFLUENCER_PT = {
    desconhecido: 'Desconhecido',
    conhecido: 'Conhecido',
    interessado: 'Interessado',
    engajado: 'Engajado',
    promotor: 'Promotor'
};