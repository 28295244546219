import { LanguageProvider } from '../../providers/language.provider';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorMessage } from 'assets/error-messages/error-message';
import { ERROR_MESSAGES_PT } from 'assets/error-messages/error-messages.pt';
import { ERROR_MESSAGES_EN } from 'assets/error-messages/error-messages.en';

@Injectable({
    providedIn: 'root'
})
export class ExceptionErrorMessagesProvider {
    constructor(
        private languageProvider: LanguageProvider
    ) {
    }

    public loadDictionary(): Array<ErrorMessage> {
        return this.languageProvider.getDefaultLanguage().toLowerCase() === 'pt' ? ERROR_MESSAGES_PT : ERROR_MESSAGES_EN;
    }

    public getTranslation(key: string): Observable<ErrorMessage> {
        return Observable.create((obs: Subject<ErrorMessage>) => {
            this.findTranslation(this.loadDictionary(), key, obs);
        });
    }

    public getTranslations(keys: string[]): Observable<ErrorMessage[]> {
        return Observable.create((obs: Subject<ErrorMessage[]>) => {
            this.findTranslations(this.loadDictionary(), keys, obs);
        });
    }

    public getTranslationFromDictionary(dictionary: ErrorMessage[], key: string): ErrorMessage {
        let translation: ErrorMessage = dictionary.find((message) => message.key.toUpperCase() === key.toUpperCase());
        if (!translation) {
            translation = { key, summary: 'Unhandled Error', detail: key, translated: false };
        } else {
            translation.translated = true;
        }
        return translation;
    }

    public findTranslation(dictionary: ErrorMessage[], key: string, obs: Subject<ErrorMessage>) {
        obs.next(this.getTranslationFromDictionary(dictionary, key));
        obs.complete();
    }

    public findTranslations(dictionary: ErrorMessage[], keys: string[], obs: Subject<ErrorMessage[]>) {
        const translations: ErrorMessage[] = [];
        keys.forEach((key) => {
            translations.push(this.getTranslationFromDictionary(dictionary, key));
        });
        obs.next(translations);
        obs.complete();
    }

    public getTranslationByKey(key: string): Observable<ErrorMessage> {
        return this.getTranslation(key);
    }

    public getTranslationByKeys(keys: string[]): Observable<ErrorMessage[]> {
        return this.getTranslations(keys);
    }
}
