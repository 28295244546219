import * as CryptoJS from 'crypto-js';

export class EncryptionUtils {
    public static readonly KEY = '#abc1234!.->|321ç,:^~ {}@';

    public static encryptStr(str: string): string {
        return CryptoJS.AES.encrypt(str, EncryptionUtils.KEY).toString();
    }

    public static descryptStr(str: string): string {
        return CryptoJS.AES.decrypt(str, EncryptionUtils.KEY).toString(CryptoJS.enc.Utf8);
    }
}
