
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { empty, Observable, throwError as observableThrowError, timer } from 'rxjs';
import { mergeMap, retryWhen, take } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { LoginProvider } from '../../providers/login.provider';
import { CommonApiResponse } from '../../shared/models/common-api-response.model';
import { ErrorService } from '../error-handling.service';
import { DontShowExceptionList } from '../exceptions/dontshow-exceptions-list';
import { ErrorMessagesService } from '../exceptions/error-messages.service';
import { HandledException } from '../exceptions/handled-exception.model';
import { LogsOffExceptionList } from '../exceptions/logsoff-exceptions-list';
import { RetryExceptionList } from '../exceptions/retry-exception-list';
import { Url } from '../urls';
import { objectImplementsInterface } from '../utils/class-utils';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
    public readonly _timeForAttemptInMS: number = 4000;

    constructor(
        private _router: Router,
        private _url: Url,
        private _zone: NgZone,
        private _loginProvider: LoginProvider,
        private _errorService: ErrorService,
        private _errorMessagesService: ErrorMessagesService
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(req).pipe(retryWhen((attempts) => {
                let count = 0;
                return attempts.pipe(mergeMap((error) => {
                    if (this.handleKnownErrorsWithRetry(error)) {
                        console.warn('Trying request', req.url);
                        console.warn('Request Body:', req.body);
                        console.warn('Retry number:', ++count);
                        return timer(this._timeForAttemptInMS);
                    } else {
                        this._zone.run(() => {
                            if (error instanceof HttpErrorResponse) {
                                const errorObj = error;
                                if (objectImplementsInterface(errorObj.error, new CommonApiResponse())) {
                                    return this.handleErrors(req.url, error, req.body);
                                } else {
                                    return observableThrowError(error);
                                }
                            }
                        });
                    }
                    return observableThrowError(error);
                }));
            }));
    }

    public handleErrors(url: string | Request, error: HttpErrorResponse, body: any): Observable<any> {
        if ((error.status === 401 || error.status === 403 || (error.error && !isNullOrUndefined(error.error.ErrorMessage) && error.error.ErrorMessage === 'INVALID_USER')) &&
            (error.url.indexOf(this._url.urlGateway) >= 0 || error.url.indexOf(this._url.urlAuth) >= 0)) {
            // For some reason, 'router.navigate' doesn´t work and the app freezes. Either way, AuthGuard doesn´t get hit after it.
            // Calling manual logout and reload the page
            this._loginProvider.logoutObservable().pipe(take(1)).subscribe(() => {
                this._loginProvider.clearSession();
                location.reload();
            });
            return;
        } else if (error.status !== 400 && error.status !== 412) {
            const errorObj: HandledException = {
                url: error.url,
                referenceRoute: this._router.url,
                body: JSON.parse(body),
                errorMessage: error.error.ErrorMessage,
                version: error.error.Version,
                requestId: error.error.RequestId,
                result: error.error.Result,
                statusCode: error.error.StatusCode,
                stack: undefined
            };
            this._errorService.updateMessage(errorObj);
            this._zone.run(() => {
                this._router.navigate(['/error']);
            });
        }
        if (error.status === 412 && !(url instanceof Request)) {
            if (LogsOffExceptionList.isErrorOnList(LogsOffExceptionList.exceptionMessageList(), error.error.ErrorMessage)) {
                this._zone.run(() => {
                    this._router.navigate(['/auth/logout']);
                    return empty;
                });
            } else {
                if (!DontShowExceptionList.isErrorOnList(DontShowExceptionList.exceptionMessageList(), error.error.ErrorMessage)) {
                    this._errorMessagesService.addErrorMessage({ severity: 'error', summary: error.statusText, detail: error.error.ErrorMessage });
                }
            }
        }
        return observableThrowError(error);
    }

    public handleKnownErrorsWithRetry(errorResponse: HttpErrorResponse): boolean {
        try {
            return errorResponse.status === 0 || (errorResponse && errorResponse.error) && (errorResponse.error as Object).hasOwnProperty('ErrorMessage') && RetryExceptionList.isErrorOnList(RetryExceptionList.exceptionMessageList(), errorResponse.error.ErrorMessage) || (errorResponse !== undefined && errorResponse.status !== undefined && (errorResponse.status === 503 || errorResponse.status === 504));
        } catch (error) {
            console.log('processErrorResponse->', error);
        }
    }
}
