import { Injectable } from '@angular/core';
import { Influencer } from '../../shared/models/influencer.model';
import { FilterAutoCompleteBaseComponent } from '../filters/components/filter-autocomplete-base.component';
import { FilterAutoCompleteComponent } from '../filters/components/filter-autocomplete.component';
import { FilterGooglePlaceAutoCompleteComponent } from '../filters/components/filter-googleplace-autocomplete.component';
import { FilterInfluencerAutoCompleteComponent } from '../filters/components/filter-influencer-autocomplete.component';
import { FilterMultipleCheckComponent } from '../filters/components/filter-multiple-check.component';
import { FilterMultipleSelectRangeComponent } from '../filters/components/filter-multiple-select-range.component';
import { FilterSelectComponent } from '../filters/components/filter-select.component';
import { FilterSocialNetworkComponent } from '../filters/components/filter-social-network.component';
import { FilterSpinnerRangeComponent } from '../filters/components/filter-spinner-range/filter-spinner-range.component';
import { AdvancedFilter } from '../models/advancedfilter';
import { ExternalTagTypeEnum } from '../models/external-tag-type.enum';

@Injectable()
export class SearchProvider {
    public lastSearchList: Influencer[];

    public audienceFields: string[] = ['audienceAgeTypes', 'audienceGenders', 'audienceLanguages', 'audienceRegions', 'interests'];

    public getTagTypeFromFilterCheckComponent<T>(filter: FilterAutoCompleteBaseComponent<T> | FilterMultipleCheckComponent | FilterMultipleSelectRangeComponent | FilterSocialNetworkComponent | FilterAutoCompleteComponent | FilterGooglePlaceAutoCompleteComponent | FilterInfluencerAutoCompleteComponent | FilterSelectComponent| FilterSpinnerRangeComponent): ExternalTagTypeEnum {
        switch (filter.filterKey) {
            case 'influencerName':
                return ExternalTagTypeEnum.Influencer;
            case 'influencerAgeTypes':
                return ExternalTagTypeEnum.Influencer;
            case 'influencerLanguages':
                return ExternalTagTypeEnum.Influencer;
            case 'influencerGenders':
                return ExternalTagTypeEnum.Influencer;
            case 'socialNetworks':
                return ExternalTagTypeEnum.Influencer;
            case 'reachRanges':
                return ExternalTagTypeEnum.Influencer;
            case 'influencerGeoLocation':
                return ExternalTagTypeEnum.Influencer;
            case 'interests':
                return ExternalTagTypeEnum.About;
            case 'brands':
                return ExternalTagTypeEnum.About;
            case 'places':
                return ExternalTagTypeEnum.About;
            case 'visitedPlaceGeoLocation':
                return ExternalTagTypeEnum.About;
            default:
                return ExternalTagTypeEnum.Audience;
        }
    }

    public isAudienceFilter(filter: string) {
        return this.audienceFields.filter((x) => x === filter).length > 0;
    }

    public getTagOrder(filter: AdvancedFilter, filterKey: string): number {
        const idx = filter.audienceOrderFields.findIndex((x) => x.value === filterKey);
        try {
            return filter.audienceOrderFields[idx].order;
        } catch (error) {
            return filter.audienceOrderFields.length + 1;
        }
    }

    public setLastSearch(listInfluencer: Influencer[]) {
        this.lastSearchList = listInfluencer;
    }

    public getLastSearch(): Influencer[] {
        return this.lastSearchList;
    }
}
