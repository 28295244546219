import { Injectable } from '@angular/core';
import { StoreHelper } from '../../crosscutting/store-helper';

@Injectable()
export class AuthService {
  public redirectUrl = '';
  public loginUrl = '/auth';
  public isloggedIn = false;

  public isUserAuthenticated(): boolean {
    this.isloggedIn = StoreHelper.storagesHasValue();
    return this.isloggedIn;
  }

  public getRedirectUrl(): string {
    return this.redirectUrl;
  }

  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  public getLoginUrl(): string {
    return this.loginUrl;
  }

  public getLogoutUrl(): string {
    return `${this.loginUrl}/logout`;
  }

  public logoutUser(): void {
    this.isloggedIn = false;
  }
}
