import { Injectable } from '@angular/core';

@Injectable()
export class CountryProvider {
    public pictureUrl(countryCode: string): string {
        switch (countryCode) {
            case null:
                return `/assets/img/flags/flag-ptbr.png`;
            case 'BR':
                return `/assets/img/flags/flag-ptbr.png`;
            case 'US':
                return `/assets/img/flags/flag-en.png`;
            default:
                return `/assets/img/flags/flag-${countryCode.toLowerCase()}.png`;
        }
    }
}