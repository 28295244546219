import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class BodyProvider {

    private get buildCss(): string {
        let cssClass = '';

        this._css.forEach(css => {
            cssClass += ` ${css}`;
        });
        return cssClass;
    }

    constructor() {
        this._css = [''];
        this.onChangeCss.emit(this.buildCss);
    }

    private _css: string[];

    public onChangeCss = new EventEmitter<string>();

    public removeCss(css: string) {
        this._css.splice(this._css.indexOf(css), 1);
        this.onChangeCss.emit(this.buildCss);
    }
    public addCss(css: string) {
        this._css.push(css);
        this.onChangeCss.emit(this.buildCss);
    }

}
