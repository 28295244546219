import { Injectable } from '@angular/core';
import { HandledException } from './exceptions/handled-exception.model';
import { ReplaySubject, Observable } from 'rxjs';

@Injectable()
export class ErrorService {
    private readonly emptyErrorObj: HandledException = {
        url: '',
        referenceRoute: '',
        body: undefined,
        errorMessage: '',
        version: '',
        requestId: '',
        result: undefined,
        statusCode: undefined,
        stack: undefined
    };
    private updateSubject: ReplaySubject<HandledException> = new ReplaySubject<HandledException>();
    public update$: Observable<HandledException> = this.updateSubject.asObservable();

    public updateMessage(message: HandledException) {
        this.updateSubject.next(message);
    }
}
