<!-- HEADER -->
<div [ngClass]="{'hidden' : !isAuth}">
    <nav id="cheader" class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
      <div class="container">
        <a class="navbar-brand"><img src="/assets/img/influencyme.png" /></a>
        <button class="navbar-toggler" type="button" (click)="setMenuMobileOpenOrClose()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div [ngClass]="{'show' : navClick}" class="collapse navbar-collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li [ngClass]="{'active' : menuHeader === 'influencergrid'}" class="nav-item" pTooltip="Grid" tooltipPosition="bottom">
              <a class="nav-link" href="#/influencergrid" (click)="menuHeader = 'influencergrid'">
                <i class="fa fa-table" aria-hidden="true"></i>
                <span class="ms-1" i18n>Grid</span>
              </a>
            </li>
<!--            <li [ngClass]="{'active' : menuHeader === 'search'}" class="nav-item" pTooltip="Descobrir" tooltipPosition="bottom">
              <a class="nav-link" href="#/search" (click)="menuHeader = 'search'">
                <i class="fa fa-search" aria-hidden="true"></i>
                <span class="ms-1" i18n>Descobrir</span>
              </a>
            </li>-->
            <!-- <li [ngClass]="{'active' : menuHeader === 'hashtag-search'}" class="nav-item">
              <a class="nav-link" href="#/radar" (click)="menuHeader = 'customers'">
                <i class="fa fa-rss" aria-hidden="true"></i>
                <span class="ms-1" i18n>Radar de perfis</span>
              </a>
            </li> -->
<!--            TODO: Remover todo codigo referente ao dataset-->
<!--            <li [ngClass]="{'active' : menuHeader === 'dataset'}" class="nav-item" pTooltip="Dataset" tooltipPosition="bottom">-->
<!--              <a class="nav-link" href="#/dataset" (click)="menuHeader = 'dataset'">-->
<!--                <i class="fa fa-sitemap" aria-hidden="true"></i>-->
<!--                <span class="ms-1" i18n>Dataset</span>-->
<!--              </a>-->
<!--            </li>-->
            <li [ngClass]="{'active' : menuHeader === 'archetype'}" class="disabled-more nav-item" pTooltip="Em manutenção" tooltipPosition="bottom">
              <a class="disabled-div nav-link" href="#/archetype" (click)="menuHeader = 'archetype'">
                <i class="fa fa-address-card-o" aria-hidden="true"></i>
                <span class="ms-1" i18n>Arquétipos</span>
              </a>
            </li>
            <li [ngClass]="{'active' : menuHeader === 'customers'}" class="nav-item" pTooltip="Clientes" tooltipPosition="bottom">
              <a class="nav-link" href="#/list-customer" (click)="menuHeader = 'customers'">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
                <span class="ms-1" i18n>Clientes</span>
              </a>
            </li>
            <li [ngClass]="{'active' : menuHeader === 'users'}" class="nav-item" pTooltip="Usuários" tooltipPosition="bottom">
              <a class="nav-link" href="#/users" (click)="menuHeader = 'users'">
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="ms-1" i18n>Usuários</span>
              </a>
            </li>
            <li [ngClass]="{'active' : menuHeader === 'userInfluencers'}" class="nav-item" pTooltip="Usuários do Public" tooltipPosition="bottom">
              <a class="nav-link" href="#/userInfluencers" (click)="menuHeader = 'userInfluencers'">
                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                <span class="ms-1" i18n>Usuários do Public</span>
              </a>
            </li>
            <li [ngClass]="{'active' : menuHeader === 'plans'}" class="nav-item" pTooltip="Planos" tooltipPosition="bottom">
              <a class="nav-link" href="#/plans" (click)="menuHeader = 'plans'">
                <i class="fa fa-trophy" aria-hidden="true"></i>
                <span class="ms-1" i18n>Planos</span>
              </a>
            </li>
            <li [ngClass]="{'active' : menuHeader === 'automation'}" class="nav-item" pTooltip="Automação" tooltipPosition="bottom">
              <a class="nav-link" href="#/automation" (click)="menuHeader = 'automation'">
                <i class="fa fa-gears" aria-hidden="true"></i>
                <span class="ms-1" i18n>Automação</span>
              </a>
            </li>
            <li [ngClass]="{'active' : menuHeader === 'cpm'}" class="nav-item" pTooltip="CPM" tooltipPosition="bottom">
              <a class="nav-link" href="#/cpm" (click)="menuHeader = 'cpm'">
                <i class="fa fa-bars" aria-hidden="true"></i>
                <span class="ms-1" i18n>CPM</span>
              </a>
            </li>
            <li [ngClass]="{'active' : menuHeader === 'keywords'}" class="nav-item" pTooltip="Keywords" tooltipPosition="bottom">
              <a class="nav-link" href="#/keywords" (click)="menuHeader = 'keywords'">
                <i class="fa fa-key" aria-hidden="true"></i>
                <span class="ms-1" i18n>Keywords</span>
              </a>
            </li>
<!--            TODO: Remover todo codigo referente ao seed-->
<!--            <li [ngClass]="{'active' : menuHeader === 'seeds'}" class="nav-item" pTooltip="Seeds" tooltipPosition="bottom">-->
<!--              <a class="nav-link" href="#/influencer-seed" (click)="menuHeader = 'seeds'">-->
<!--                <i class="fa fa-user-plus" aria-hidden="true"></i>-->
<!--                <span class="ms-1" i18n>Seeds</span>-->
<!--              </a>-->
<!--            </li>-->
            <li [ngClass]="{'active' : menuHeader === 'loginInformation'}" class="nav-item" pTooltip="Informações Login Crawler" tooltipPosition="bottom">
              <a class="nav-link" href="#/loginInformation" (click)="menuHeader = 'loginInformation'">
                <i class="fa fa-list-ol" aria-hidden="true"></i>
                <span class="ms-1" i18n>Informações Login Crawler</span>
              </a>
            </li>
            <li [ngClass]="{'active' : menuHeader === 'quotation'}" class="nav-item" pTooltip="Pedidos de cotação" tooltipPosition="bottom">
              <a class="nav-link" href="#/quotations" (click)="menuHeader = 'quotation'">
                <i class="fa-solid fa-comments-dollar"></i>
                <span class="ms-1" i18n>Pedidos de cotação</span>
              </a>
            </li>
          </ul>
          <div class="d-flex">
            <user-header (onManagerClick)="openAccounManager = true"> </user-header>
          </div>
        </div>
      </div>
    </nav>

    <div class="container interna">
        <show-error></show-error>
        <router-outlet></router-outlet>
    </div>

    <footer>
        <div class="container"><span>&copy;</span><span i18n>2023 Grupo Comunique-se. Todos os direitos
                reservados.</span></div>
    </footer>
</div>
