import { Url } from 'app/crosscutting/urls';
import { LanguageProvider } from 'app/providers/language.provider';
import { I18nMessagesSelect } from 'assets/i18n/messages.selects';
import { I18nMessagesSelectEnglish } from 'assets/i18n/messages.selects.en';

export const COMMON_PROVIDERS: any[] = [
    // common env directives Global
];

// Environment Providers
const PROVIDER_URL: any[] = [
    // common env directives
    { provide: Url, useClass: Url }
];

const TRANSLATE_PROVIDERS: any[] = [
    // common env directives Global
];

const localeLocal = LanguageProvider.getDefaultLanguage('pt');
if (localeLocal === 'en') {
    TRANSLATE_PROVIDERS.push({ provide: I18nMessagesSelect, useClass: I18nMessagesSelectEnglish });
} else {
    TRANSLATE_PROVIDERS.push({ provide: I18nMessagesSelect, useClass: I18nMessagesSelect });
}

COMMON_PROVIDERS.push(PROVIDER_URL);
COMMON_PROVIDERS.push(TRANSLATE_PROVIDERS);
