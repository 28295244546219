import { Inject, Injectable } from '@angular/core';
import { AppConfigModel } from '../providers/app-config.model';
import { APP_CONFIG } from '../providers/app-config.token';
import { LanguageProvider } from '../providers/language.provider';
import { DeleteSocialNetworkUpdateDates } from 'app/+influencer/+profile/edit/component/influencer-profile-social-media-control/model/delete-social-network-update-dates.model';
@Injectable({ providedIn: 'root' })
export class Url {
    public urlAuth: string;
    public urlMe: string;
    public urlPublic: string;
    public cdnImages: string;
    public cdnAssets: string;
    public urlGateway: string;
    public urlApp: string;

    constructor(@Inject(APP_CONFIG) public config: AppConfigModel, private readonly languageProvider: LanguageProvider) {
        this.readConfigFile();
    }

    public readConfigFile(): void {
        this.urlGateway = this.config.urlGateway;
        this.urlAuth = this.config.urlAuth;
        this.urlMe = `${this.config.urlMe}/${this.languageProvider.getDefaultLanguage()}`;
        this.urlPublic = this.config.urlPublic;
        this.urlApp = this.config.urlApp;
        this.cdnImages = this.config.cdnImages;
        this.cdnAssets = this.config.cdnAssets;
    }

    public get validateEmailKickBox(): string {
        return this.urlGateway + '/api/Email/validateEmailKickBox';
    }

    public get getToken(): string {
        return this.urlAuth + '/connect/token';
    }

    public get createUser(): string {
        return this.urlAuth + '/account/createuser';
    }

    public get updateUser(): string {
        return this.urlAuth + '/account/updateUser';
    }

    public get logout(): string {
        return this.urlAuth + '/Account/logout';
    }

    public getAllLists(step: number): string {
        return this.urlGateway + '/api/User/GetAllLists?step=' + step;
    }

    public register(link: string): string {
        return this.urlGateway + '/api/User/Register/' + link;
    }

    public getUserInfo(email: string): string {
        return this.urlGateway + '/api/User/GetUserInformation?userEmail=' + email;
    }

    public getUserInfoForAdmin(email: string): string {
        return this.urlGateway + '/api/User/GetUserInformationForAdmin?userEmail=' + email;
    }

    public get verifyIfUserExist(): string {
        return this.urlGateway + '/api/User/VerifyIfUserExist';
    }

    public get authFacebookRegister(): string {
        return this.urlAuth + '/accountfacebook?accessUrl=' + this.urlMe + '/--/auth/sigin-external-register&unaccessUrl=' + this.urlMe + '/--/auth/register';
    }

    public get authGoogleRegister(): string {
        return this.urlAuth + '/accountgoogle?accessUrl=' + this.urlMe + '/--/auth/sigin-external-register&unaccessUrl=' + this.urlMe + '/--/auth/register';
    }

    public get authFacebookLogin(): string {
        return this.urlAuth + '/accountFacebook/login?accessUrl=' + this.urlMe + '/--/auth/sigin-external-login&unaccessUrl=' + this.urlMe + '/--/auth/login';
    }

    public get authGoogleLogin(): string {
        return this.urlAuth + '/accountgoogle/Login?accessUrl=' + this.urlMe + '/--/auth/sigin-external-login&unaccessUrl=' + this.urlMe + '/--/auth/login';
    }

    public get getTopTen(): string {
        return this.urlGateway + '/api/Influencer/GetTopTen';
    }

    public get getLadderOfInfluence(): string {
        return this.urlGateway + '/api/LadderOfInfluencer/GetLadderOfInfluence';
    }

    public get updateLadderOfInfluence(): string {
        return this.urlGateway + '/api/LadderOfInfluencer/UpdateLadderOfInfluence';
    }
    public get insertLadderOfInfluence(): string {
        return this.urlGateway + '/api/LadderOfInfluencer/InsertLadderOfInfluence';
    }

    public get getAllCustomers(): string {
        return this.urlGateway + '/api/Customer/GetAllCustomers';
    }

    public get removeInfluencerInLadder(): string {
        return this.urlGateway + '/api/LadderOfInfluencer/DeleteLadderOfInfluence';
    }

    public get removeUserIdentity(): string {
        return this.urlAuth + '/account/removeUserIdentity';
    }

    public get insertInfluencerInLadderOfInfluence(): string {
        return this.urlGateway + '/api/LadderOfInfluencer/InsertInfluencerInLadderOfInfluence';
    }

    public get insertInfluencer(): string {
        return this.urlGateway + '/api/Influencer/InsertInfluencer';
    }

    public get insertGuest(): string {
        return this.urlGateway + '/api/Guest/InsertGuest';
    }
    public get influencersSuggestion(): string {
        return this.urlGateway + '/api/Influencer/GetSuggestedInfluencersByCustomerId';
    }

    public get disableShowSuggestedInfluencer(): string {
        return this.urlGateway + '/api/Customer/DisableShowSuggestedInfluencer';
    }

    public get enableShowSuggestedInfluencer(): string {
        return this.urlGateway + '/api/Customer/EnableShowSuggestedInfluencer';
    }

    public get searchInfluencerAutoComplete(): string {
        return this.urlGateway + '/api/Influencer/AutoComplete';
    }

    public get searchInfluencersAdvancedFilter(): string {
        return this.urlGateway + '/api/Influencer/AdvancedFilter';
    }

    public getAll(skip: number, take: number, orderby: string): string {
        return this.urlGateway + '/api/Influencer/GetAll?skip=' + skip + '&take=' + take + '&orderby=' + orderby;
    }

    public getInformationInfluencer(influencerId: string): string {
        return this.urlGateway + '/api/Influencer/GetInformationInfluencer?influencerId=' + influencerId;
    }

    public getGrid(skip: number, take: number, sortField: string | string[], sortOrder: string): string {
        return this.urlGateway + `/api/InfluencerSeed/GetGrid?skip=${skip}&take=${take}&sortField=${sortField}&sortOrder=${sortOrder}`;
    }

    public get addSeeder(): string {
        return `${this.urlGateway}/api/InfluencerSeed/AddSeeder`;
    }

    public reprocessSeeder(seederId: string): string {
        return `${this.urlGateway}/api/InfluencerSeed/ReprocessSeeder/${seederId}`;
    }

    public getInfluencerByUrl(socialUrl: string) {
        return this.urlGateway + '/api/Social/GetInfluencerByUrl?url=' + socialUrl;
    }

    public GetSocialNetworkByUrl(socialUrl: string) {
        return this.urlGateway + '/api/Social/GetSocialNetworkByUrl?url=' + socialUrl;
    }

    public get getLastAppliedFilter(): string {
        return this.urlGateway + '/api/Influencer/LastAppliedFilter';
    }

    public get clearLastAppliedFilter(): string {
        return this.urlGateway + '/api/Influencer/ClearLastAppliedFilter';
    }

    public getAvailableFilters(lang: string): string {
        return this.urlGateway + '/api/Influencer/GetAvailableFilters?language=' + lang;
    }

    public get getAllActivitiesByInfluencerAndDate(): string {
        return this.urlGateway + '/api/Activity/GetAllByInfluencerAndDate';
    }

    public get getAllActivityCategories(): string {
        return this.urlGateway + '/api/Activity/GetAllCategories';
    }

    public get saveActivity(): string {
        return this.urlGateway + '/api/Activity/Save';
    }

    public get deleteActivity(): string {
        return this.urlGateway + '/api/Activity/Delete';
    }

    public get getActivityListByInfluencer(): string {
        return this.urlGateway + '/api/Activity/GetListByInfluencer';
    }

    public get getActivityById(): string {
        return this.urlGateway + '/api/Activity/GetById';
    }

    public getTimeLineByInfluencerId(influencerId: string): string {
        return this.urlGateway + '/api/Social/GetTimeLineByInfluencerId?influencerId=' + influencerId;
    }

    public refreshTimeLineByInfluencerId(influencerId: string): string {
        return this.urlGateway + '/api/Social/RefreshTimeLineByInfluencerId?influencerId=' + influencerId;
    }

    public getActivityStatusByInfluencer(customerId: string, influencerId: string): string {
        return this.urlGateway + '/api/Activity/GetStatusByInfluencer?customerId=' + customerId + '&influencerId=' + influencerId;
    }

    public get insertInfluencerToCustomer(): string {
        return this.urlGateway + '/api/Influencer/AssignInfluencerToCustomer';
    }

    public getAllInfluencersByCustomer(customerId: string, orderType: string, orderField: string): string {
        return this.urlGateway + '/api/Influencer/GetAllInfluencersByCustomerId?customerId=' + customerId + '&orderType=' + orderType + '&orderField=' + orderField;
    }

    public GetActivityTimelineDtoByCustomerAndInfluencerAndUser(customerId: string, influencerId: string, userId: string): string {
        return this.urlGateway + '/api/TimelineActivity/GetActivityTimelineDtoByCustomerAndInfluencerAndUser?customerId=' + customerId
            + '&influencerId=' + influencerId + '&assignedToUserId=' + userId;
    }

    public getNoteById(noteId: string): string {
        return this.urlGateway + '/api/Note/GetById?id=' + noteId;
    }

    public get deleteNote(): string {
        return this.urlGateway + '/api/Note/Delete';
    }

    public get saveNote(): string {
        return this.urlGateway + '/api/Note/Save';
    }

    public get setPinned(): string {
        return this.urlGateway + '/api/Note/SetPinned';
    }

    public get setUnpinned(): string {
        return this.urlGateway + '/api/Note/SetUnpinned';
    }

    public get setAccomplished(): string {
        return this.urlGateway + '/api/Activity/SetAccomplished';
    }

    public get setNotAccomplished(): string {
        return this.urlGateway + '/api/Activity/SetNotAccomplished';
    }

    public get getActivityListItemByInfluencer(): string {
        return this.urlGateway + '/api/Activity/GetListItemByInfluencer';
    }

    public get searchInfluencersAdvancedFilterWhithoutSaveSearch(): string {
        return this.urlGateway + '/api/Influencer/AdvancedFilterWithoutSaveSearch';
    }

    public isInfluencerAlreadyClaimed(influencerId: string, email: string) {
        return this.urlGateway + `/api/Influencer/IsInfluencerAlreadyClaimed?influencerId=${influencerId}&email=${email}`;
    }

    public getInfluencerForEdit(influencerId: string, email: string) {
        return this.urlGateway + `/api/Influencer/GetInfluencerForEditById?influencerId=${influencerId}&email=${email}`;
    }

    public getNextInfluencerForEdit(email: string) {
        return this.urlGateway + `/api/Influencer/GetInfluencerForEditById?email=${email}`;
    }

    public get updateInfluencer() {
        return this.urlGateway + '/api/Influencer/UpdateInfluencer';
    }

    public get resetClaimDate() {
        return this.urlGateway + '/api/Influencer/ResetClaimDate';
    }

    public get startAudienceAnalyzer(): string {
        return this.urlGateway + '/api/AudienceAnalyzer/StartAudienceAnalyzer';
    }

    public get refreshInfluencerInformationByInfluencerUpdate() {
        return this.urlGateway + '/api/Social/RefreshInformationByInfluencerDto';
    }

    public get updateInfluencerLocation() {
        return this.urlGateway + '/api/admin/UpdateInfluencerLocation';
    }

    public deleteSocialNetworkUpdateDates(request: DeleteSocialNetworkUpdateDates): string {
        return this.urlGateway + `/api/admin/DeleteSocialNetworkUpdateDates?influencerSocialNetworkId=${request.influencerSocialNetworkId}&updateTypes=${request.updateTypes.join('&updateTypes=')}`;
    }

    public deleteInfluencerSocialNetwork(influencerId: string, influencerSocialNetworkId: string): string {
        return this.urlGateway + `/api/InfluencerSocialNetwork/${influencerId}/SocialNetworks/${influencerSocialNetworkId}`;
    }

    public getTopics(text: string, topicGroupCode: string) {
        return this.urlGateway + `/api/Topic/SearchTopic?q=${text}&type=${topicGroupCode}`;
    }

    public influencerToForcePostAnalyzer(influencerId: string) {
        return this.urlGateway + `/api/Influencer/InfluencerToForcePostAnalyzer?influencerId=${influencerId}&rebuildFile=true`;
    }

    public getTopicsByParent(parentId: string, topicGroupCode: string) {
        const newParentIdParam = parentId ? `parentId=${parentId}&` : '';
        return this.urlGateway + `/api/Topic/GetTopicsByParent?${newParentIdParam}type=${topicGroupCode}`;
    }

    public getTopicBySearch(fullPath: string, topicGroupCode: string) {
        return this.urlGateway + `/api/Topic/GetTopicBySearch?fullPath=${fullPath}&type=${topicGroupCode}`;
    }
    public getTopicById(topicId: string) {
        return this.urlGateway + `/api/Topic/GetTopicById?topicId=${topicId}`;
    }
    public insertTopic() {
        return this.urlGateway + '/api/Topic/InsertTopic';
    }
    public updateTopic() {
        return this.urlGateway + '/api/Topic/UpdateTopic';
    }
    public influencerExistsInELK(influencerId: string) {
        return this.urlGateway + `/api/Influencer/InfluencerExistsInELK?influencerId=${influencerId}`;
    }
    public influencerExistsInDB(influencerId: string) {
        return this.urlGateway + `/api/Influencer/InfluencerExistsInDB?influencerId=${influencerId}`;
    }
    public get deleteOrphanedInfluencersByIds() {
        return this.urlGateway + `/api/Influencer/DeleteOrphanedInfluencersByIds`;
    }
    public get getArchetypeForGridView(): string {
        return this.urlGateway + `/api/ArchetypePattern/GetArchetypeForGridView`;
    }
    public getArchetypeById(archetypeId: string): string {
        return this.urlGateway + `/api/ArchetypePattern/GetArchetypePatternById?id=${archetypeId}`;
    }
    public get saveArchetype(): string {
        return this.urlGateway + `/api/ArchetypePattern/Save`;
    }
    public get deleteArchetype(): string {
        return this.urlGateway + `/api/ArchetypePattern/Delete`;
    }
    public get fileUploadPath(): string {
        return this.urlGateway + `/api/File/Upload`;
    }
    public get findPlaces(): string {
        return `${this.urlGateway}/api/GooglePlace/FindPlaces`;
    }
    public get placeDetails(): string {
        return `${this.urlGateway}/api/GooglePlace/PlaceDetails`;
    }
    public getInstagramAutocompleteLocation(location: string): string {
        return `${this.urlGateway}/api/LocationMonitor/GetAutocompleteLocation?location=${location}`;
    }
    public getLinkPreview(url: string) {
        return `${this.urlGateway}/api/WebClient/GetLinkPreview?url=${url}`;
    }
    public get getInfluencerGridForAdmin() {
        return `${this.urlGateway}/api/Influencer/GetInfluencerGridForAdmin`;
    }
    public searchInfluencers(query: string): string {
        return `${this.urlGateway}/api/Influencer/SearchInfluencers?query=${query}`;
    }
    public getMergeVerificationPossibility(sourceInfluencerId: string, targetInfluencerId: string): string {
        return `${this.urlGateway}/api/MergeInfluencer/${sourceInfluencerId}?targetInfluencerId=${targetInfluencerId}`;
    }
    public get mergeInfluencers(): string {
        return `${this.urlGateway}/api/MergeInfluencer/MergeInfluencers`;
    }
    public getAdminGridLastAppliedFilter(userId: string) {
        return `${this.urlGateway}/api/Influencer/GetAdminGridLastAppliedFilter?userId=${userId}`;
    }
    public get registerAdminGridLastAppliedFilter() {
        return `${this.urlGateway}/api/Influencer/RegisterAdminGridLastAppliedFilter`;
    }
    public get genericUpdateInfluencer() {
        return `${this.urlGateway}/api/Influencer/GenericUpdateInfluencer`;
    }
    public get getRunningAnalysisForMassUpdate() {
        return `${this.urlGateway}/api/PostAnalyzer/GetRunningAnalysisForMassUpdate`;
    }
    public get updateInfluencerSocialNetworks(): string {
        return this.urlGateway + `/api/Influencer/UpdateInfluencerSocialNetworks`;
    }
    public get deleteInfluencer(): string {
        return `${this.urlGateway}/api/Influencer/DeleteInfluencer`;
    }
    public get getAllCustomersToAccessAs(): string {
        return this.urlGateway + `/api/Customer/GetAll`;
    }
    public get getCustomerById(): string {
        return this.urlGateway + `/api/Customer/GetCustomer`;
    }
    public get setCustomer(): string {
        return this.urlGateway + `/api/Customer/SetCustomer`;
    }
    public getCuratorUsers(): string {
        return this.urlGateway + '/api/User/GetCuratorUsers';
    }
    public get getAllIndexedTopics(): string {
        return this.urlGateway + `/api/Topic/GetAllIndexedTopics`;
    }
    public get executePreviewHashtagPostAnalyzer(): string {
        return this.urlGateway + `/api/HashtagMonitor/ExecutePreviewHashtagPostAnalyzer`;
    }
    public get executePreviewLocationPostAnalyzer(): string {
        return this.urlGateway + `/api/LocationMonitor/ExecutePreviewLocationPostAnalyzer`;
    }
    public getProfileRadarByUserId(userId: string, profileRadarTypeName: string): string {
        return this.urlGateway + `/api/HashtagMonitor/GetProfileRadarByUserId?userId=${userId}&profileRadarTypeName=${profileRadarTypeName}`;
    }
    public get clearProfileRadarByUserId(): string {
        return this.urlGateway + `/api/HashtagMonitor/ClearProfileRadarByUserId`;
    }
    public get clearProfileRadarById(): string {
        return this.urlGateway + `/api/HashtagMonitor/ClearProfileRadarById`;
    }
    public get stopProfileRadarById(): string {
        return this.urlGateway + `/api/HashtagMonitor/StopProfileRadarById`;
    }
    public get insertInfluencerFromProfileRadar(): string {
        return `${this.urlGateway}/api/HashtagMonitor/InsertInfluencerFromProfileRadar`;
    }
    public get createPostAnalysisForMassUpdate(): string {
        return `${this.urlGateway}/api/PostAnalyzer/CreatePostAnalysisForMassUpdate`;
    }
    public getFilterReachRangeItens(language: string): string {
        return this.urlGateway + `/api/Influencer/GetFilterReachRangeItens`;
    }
    public getAutocompleteLocation(location: string): string {
        return `${this.urlGateway}/api/LocationMonitor/GetAutocompleteLocation?location=${location}`;
    }
    public getAllAccountUsers(customerId: string): string {
        return `${this.urlGateway}/api/User/GetUsersFromCustomerAccount?customerId=${customerId}`;
    }
    public getAllUsers(): string {
        return `${this.urlGateway}/api/User/GetAllUsers`;
    }
    public getUsersByIds(): string {
        return `${this.urlGateway}/api/User/GetUsersByIds`;
    }
    public saveUser(): string {
        return `${this.urlGateway}/api/User/UpdateUser`;
    }
    public deleteUser(): string {
        return `${this.urlGateway}/api/User/DeleteUser`;
    }
    public updateGuestRole(): string {
        return this.urlGateway + '/api/Guest/UpdateGuestRole';
    }
    public resendInvite(): string {
        return this.urlGateway + '/api/Guest/ResendInvite';
    }
    public getInviteGuestUrl(): string {
        return this.urlGateway + '/api/Admin/GetInviteGuestUrl';
    }
    public getAllGuestsByUserId(userId: string) {
        return this.urlGateway + `/api/Guest/GetAllGuestsByUserId?userId=${userId}`;
    }
    public getAllGuestsNotActivated() {
        return this.urlGateway + `/api/Guest/getAllGuestsNotActivated`;
    }
    public getAllGuest(customerId: string): string {
        return `${this.urlGateway}/api/Guest/GetAllGuests?customerId=${customerId}`;
    }
    public deleteGuest() {
        return this.urlGateway + `/api/Guest/deleteGuest`;
    }
    public get getAllSubscriptions(): string {
        return `${this.urlGateway}/api/Subscription/GetAll`;
    }
    public getSubscriptionByCode(code: string): string {
        return `${this.urlGateway}/api/Subscription/GetByCode?code=${code}`;
    }
    public getSubscriptionById(id: string): string {
        return `${this.urlGateway}/api/Subscription/GetById?id=${id}`;
    }
    public get updateSubscription(): string {
        return `${this.urlGateway}/api/Subscription/UpdateSubscription`;
    }
    public get insertSubscription(): string {
        return `${this.urlGateway}/api/Subscription/InsertSubscription`;
    }
    public get deleteSubscriptionbyId(): string {
        return this.urlGateway + '/api/Subscription/DeleteSubscription';
    }
    public getCampaignMonitoringHistoryMonthlyGraphic(customerId: string, year: number): string {
        return `${this.urlGateway}/api/Audit/GetCampaignMonitoringHistoryMonthlyGraphic?customerId=${customerId}&year=${year}`;
    }
    public getCustomerInfluencerHistoryMonthlyGraphic(customerId: string, year: number): string {
        return `${this.urlGateway}/api/Audit/GetCustomerInfluencerHistoryMonthlyGraphic?customerId=${customerId}&year=${year}`;
    }
    public getCustomerInfluencerAndCampaignHistoryPerMonth(customerId: string, year: number, month: number): string {
        return `${this.urlGateway}/api/Audit/GetCustomerInfluencerAndCampaignHistoryPerMonth?customerId=${customerId}&year=${year}&month=${month}`;
    }
    public get getAllStages() {
        return `${this.urlGateway}/api/Stage/GetAll`;
    }
    public get UpdateStagesScore() {
        return `${this.urlGateway}/api/Stage/UpdateStagesScore`;
    }
    public get getAllInfluencerScore() {
        return `${this.urlGateway}/api/InfluencerScore/GetAll`;
    }
    public get UpdateInfluencerScore() {
        return `${this.urlGateway}/api/InfluencerScore/UpdateScores`;
    }
    public get updateInfluencerBasicInfo(): string {
        return `${this.urlGateway}/api/Admin/UpdateInfluencerBasicInfo`;
    }
    public get updateInfluencerContacts(): string {
        return `${this.urlGateway}/api/Admin/UpdateInfluencerContacts`;
    }
    public get updateInfluencerSocialNetWork(): string {
        return `${this.urlGateway}/api/Admin/UpdateInfluencerSocialNetWork`;
    }
    public autoCompleteInfluencer(query: string): string {
        return `${this.urlGateway}/api/Admin/AutoCompleteInfluencer?query=${query}`;
    }

    public isConfirmEmail(email: string): string {
        return `${this.urlAuth}/Account/IsConfirmEmail?email=${email}`;
    }

    public get activateUser(): string {
        return this.urlGateway + '/api/Admin/ActivateAccount';
    }

    public get getAllSocialNetwork(): string {
        return this.urlGateway + '/api/SocialNetwork/GetAllSocialNetwork';
    }

    public get getAllKeywords(): string {
        return this.urlGateway + '/api/KeywordsPaidPosts/GetAllKeywordsPaidPosts';
    }

    public get insertKeywordPaidPosts(): string {
        return this.urlGateway + '/api/KeywordsPaidPosts/InsertKeywordPaidPosts';
    }

    public deleteKeywordPaidPosts(keywordId: string): string {
        return `${this.urlGateway}/api/KeywordsPaidPosts/DeleteKeywordPaidPosts/${keywordId}`;
    }

    public getSocialNetworkPostPriceByCode(postPriceCode: string): string {
        return `${this.urlGateway}/api/SocialNetworkPostPrice/GetSocialNetworkPostPriceByCode/${postPriceCode}`;
    }

    public get getAllSocialNetworkPostPrices(): string {
        return this.urlGateway + '/api/SocialNetworkPostPrice/GetAllSocialNetworkPostPrices';
    }

    public get insertSocialNetworkPostPrice(): string {
        return this.urlGateway + '/api/SocialNetworkPostPrice/InsertSocialNetworkPostPrice';
    }

    public get updateSocialNetworkPostPrice(): string {
        return this.urlGateway + '/api/SocialNetworkPostPrice/UpdateSocialNetworkPostPrice';
    }

    public deleteSocialNetworkPostPrice(postPriceCode: string): string {
        return `${this.urlGateway}/api/SocialNetworkPostPrice/DeleteSocialNetworkPostPrice/${postPriceCode}`;
    }

    public getInfluencerDetailsAppUrl(influencerId: string): string {
        return `${this.urlApp}/#/influencer-details/${influencerId}`;
    }

    public get getSynonymsPreviewForTopic(): string {
        return `${this.urlGateway}/api/PostAnalyzer/GetSynonymsPreviewForTopic`;
    }

    public get unlockUser(): string {
        return `${this.urlAuth}/Operational/UnlockUser`;
    }

    public get changeCustomerOwner(): string {
        return `${this.urlGateway}/api/Operational/ChangeCustomerOwner`;
    }

    public getUserInfluencerGridForAdmin(query: string, skip: number, take: number): string {
        return `${this.urlGateway}/api/UserInfluencer/GetUserInfluencerGridForAdmin?query=${query}&skip=${skip}&take=${take}`;
    }

    public deleteUserInfluencer(email: string): string {
        return `${this.urlGateway}/api/UserInfluencer/DeleteUserInfluencer?email=${email}`;
    }

    public getUserInfluencerUsernameAndPermission(influencerId: string): string {
      return `${this.urlGateway}/api/UserInfluencer/GetUserInfluencerUsernameAndPermission/${influencerId}`;
    }

    public getLoginCountHistory(skip: number, take: number): string {
        return `${this.urlGateway}/api/LoginInformation/GetLoginCountHistory?skip=${skip}&take=${take}`;
    }

    public getActiveLogins(): string {
        return `${this.urlGateway}/api/LoginInformation/GetActiveLogins`;
    }

    public getUsersQuota(customerId: string): string {
        return `${this.urlGateway}/api/Customer/GetUsersQuota/${customerId}`;
    }

    public getCustomerExcel(customerId: string, year: number, month: number): string {
        return `${this.urlGateway}/api/Admin/GetCustomerInformationAndMonitoringExcel/${customerId}?year=${year}&month=${month}`;
    }

    public expireInfluencerCache(influencerId: string): string {
        return `${this.urlGateway}/api/InfluencerDetails/ExpireInfluencerCache/${influencerId}`;
    }

    public getCampaignQuotation(campaignQuotationId: string): string {
        return `${this.urlGateway}/api/CampaignQuotation/${campaignQuotationId}`;
    }

    public getCampaignQuotationItems(skip: number, take: number, startDate: string, endDate: string): string {
        return `${this.urlGateway}/api/CampaignQuotation?skip=${skip}&take=${take}&startDate=${startDate}&endDate=${endDate}`;
    }

    public updateCampaignQuotationStatus(campaignQuotationId: string): string {
        return `${this.urlGateway}/api/CampaignQuotation/${campaignQuotationId}/UpdateStatus`;
    }

    public getCampaignUrl(campaignId: string): string {
        return `${this.urlApp}/#/campaign-management/${campaignId}`;
    }

    public iQDataReportAvailableUpdates() {
        return `${this.urlGateway}/api/IQData/IQDataReportAvailableUpdates`;
    }
}

