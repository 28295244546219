import { enableProdMode, MissingTranslationStrategy } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import { AppConfigModel } from './app/providers/app-config.model';
import { APP_CONFIG } from './app/providers/app-config.token';


fetch('/assets/app-config.json')
  .then(response => response.json())
  .then((config: AppConfigModel) => {
    if (environment.production) {
      enableProdMode();
    }
    loadApp(config);
  }).catch((err) => {
    console.error('Failed to load config file', err);
});

function loadApp(config: AppConfigModel) {
  platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
    .bootstrapModule(AppModule).catch((err) => {
    console.log(err);
  });
}
