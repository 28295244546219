import * as moment from 'moment';

export class Token {
    public expirationDate?: moment.Moment;
    public access_token = '';
    public expires_in = 0;
    public token_type?: string;
    public username = '';
    public claims?: string[];

    constructor() {
        this.claims = new Array<string>();
    }
}
