export const I18N_INFLUENCER_TIMELINE_EN = {
    clique_aqui: 'click here',
    a_ultima_atualização_foi_a: 'The last update was the ',
    caso_queira_atualizar_os_dados_da_timeline: ' If you want to update the timeline data',
    Hoje: 'Today',
    Ontem: 'Yesterday',
    date_format: 'MM/dd',
    likes: 'Likes',
    comments: 'Comments',
    views : 'Views',
};

export const I18N_INFLUENCER_TIMELINE_PT = {
    clique_aqui: 'clique aqui',
    a_ultima_atualização_foi_a: 'A ultima atualização foi a ',
    caso_queira_atualizar_os_dados_da_timeline: ' Caso queira atualizar os dados da timeline',
    Hoje: 'Hoje',
    Ontem: 'Ontem',
    date_format: 'dd/MM',
    likes: 'Likes',
    comments: 'Comments',
    views : 'Views',
};