import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Token } from '../+auth/models/token.model';
import { HttpRequestResult } from '../crosscutting/http-request-result';
import { SubscriptionCancel } from '../crosscutting/service-subscription/subscription-cancel';
import { StoreHelper } from '../crosscutting/store-helper';
import { Url } from '../crosscutting/urls';
import { CurrentUser } from '../shared/models/current-user.model';

@Injectable()
export class LoginProvider extends SubscriptionCancel {
    constructor(
        private _http: HttpClient,
        private _url: Url,
        private _router: Router,
        private _zone: NgZone
    ) {
        super();
    }

    public getUserToken(email: string, password: string, callback: any, exceptionCallback: any, component: any): any {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const body = new HttpParams()
            .set('email', email)
            .set('password', password)
            .set('grant_type', 'cse')
            .set('client_id', 'Site')
            .set('scope', 'AudienceAnalyzer UserProfile SendEmail Influencer Gateway Social Relationship Notifier Crawler Marketplace HashtagMonitor Audit InfluencerProfile PostAnalyzer InfluencerSeeker Geolocation CampaignManagement');
        this._http.post(this._url.getToken, body, { headers }).pipe(takeUntil(this.destroy$)).subscribe(
            (response) => {
                callback(response, component);
            }, // Reach here if res.status >= 200 && <= 299
            (errorResponse: HttpErrorResponse) => {
                exceptionCallback(errorResponse.error, component);
            });
    }

    public setToken(token: Token) {
        StoreHelper.token = token;
    }

    public logout(message?: string) {
        this._http.get(this._url.logout).pipe(take(1)).subscribe(
            (response) => {
                this.callbackLogout(response, message);
            }, // Reach here if res.status >= 200 && <= 299
            (response) => {
                this.callbackLogoutException(response);
            });
    }

    private callbackLogout(response: any, message?: string) {
        this.clearSession();
        this._zone.run(() => {
            if (message) {
                this._router.navigate(['/auth'], { queryParams: { error: message } });
            } else {
                this._router.navigate(['/auth']);
            }
        });
    }

    private callbackLogoutException(response: any) {
        console.log(JSON.stringify(response));
    }

    public clearSession() {
        StoreHelper.clearLocalAndSessionStorage();
    }

    public logoutObservable(): Observable<Object> {
        return this._http.get<Observable<Object>>(this._url.logout);
    }

    public getUser(email: string): Observable<HttpRequestResult<CurrentUser>> {
        return this._http.get<HttpRequestResult<CurrentUser>>(this._url.getUserInfoForAdmin(email));
    }

    public getUserWithoutEmail(callback: any, component: any) {
        this._http.get(this._url.getUserInfoForAdmin(StoreHelper.token.username)).subscribe(
            (response) => { callback(response, component); },
            (response) => { callback(response, component); });
    }

    public RemoveIdentity(username: string) {
        this._http.request('delete', this._url.removeUserIdentity, { body: JSON.stringify(username) }).subscribe();
    }

    public clearSessionAndStopWebWorkers() {
        StoreHelper.clearLocalAndSessionStorage();
    }
}
