import { Component, HostBinding } from '@angular/core';
import { BodyProvider } from './providers/body.provider';


@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  name = 'Login Cse';
  private _baseCss = '';
  @HostBinding('class') public cssClass = '';

  constructor(private _bodyProvider: BodyProvider) {
    this._bodyProvider.onChangeCss.subscribe((cssClass) => {
      this.onChangeCss(cssClass);
    });
  }

  onChangeCss(css: string) {
    this.cssClass = this._baseCss + ' ' + css;
  }
}
