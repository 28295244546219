export abstract class ExceptionList {
    public static isErrorOnList(exceptionList: string[], error: string): boolean {
        let result = false;
        if (error !== undefined && error !== '') {
            exceptionList.forEach((x) => {
                if (error.indexOf(x) > - 1) {
                    result = true;
                    return;
                }
            });
        }
        return result;
    }
}
