import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Token } from '../+auth/models/token.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { environment } from 'environments/environment';

@Injectable()
export class AuthProvider {
    constructor(private jwtHelper: JwtHelperService) {
    }

    private isValidate(token: Token) {
        if (isNullOrUndefined(token.access_token) || token.access_token === '') {
            return false;
        }
        return true;
    }

    public isTokenValid(token: Token): boolean {
        if (this.isValidate(token)) {
            try {
                const accessToken = this.jwtHelper.decodeToken(token.access_token);
                if (isNullOrUndefined(accessToken)) {
                    return false;
                }
                const expireDate = moment(moment.unix(accessToken.exp).valueOf()).unix().valueOf();
                if (!environment.production) {
                    console.log('Token:', accessToken);
                    console.log('Expire date:', moment.unix(accessToken.exp).format('DD-MM-YYYY HH:mm'));
                }
                return expireDate > moment().unix().valueOf() && !isNullOrUndefined(token.username) && token.username !== '';
            } catch (error) {
                return false;
            }
        }
    }
}