import { ExceptionList } from './exception-list';

export class RetryExceptionList extends ExceptionList {
    public static exceptionMessageList(): string[] {
        return [
            'Collection was modified',
            'Unexpected character encountered while parsing value',
            'Service Temporarily Unavailable',
            'INTERNAL_ERROR - Connection Timeout Expired.',
            'Timeout expired.',
            'Error of processing: Transferred a partial file'
        ];
    }
}
