import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { Token } from '../../+auth/models/token.model';

export class TokenValidationUtils {
    public static readonly isValidHeader = 'tokenvalid';

    public static tokenIsValid(headers: HttpHeaders, token: Token): HttpHeaders {
        const valid = (moment(new Date()) < moment(token.expirationDate)) || isNullOrUndefined(token.expirationDate) ? '1' : '0';
        headers = headers.set(TokenValidationUtils.isValidHeader, valid);
        return headers;
    }
}
