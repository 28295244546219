import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ErrorService } from './error-handling.service';
import { HandledException } from './exceptions/handled-exception.model';
import { LogsOffExceptionList } from './exceptions/logsoff-exceptions-list';

export class UncaughtExceptionHandler implements ErrorHandler {
    public get router(): Router {
        return this.injector.get(Router);
    }

    constructor(public injector: Injector, public ngZone: NgZone, public errorService: ErrorService) {
    }

    public onZoneError(error) {
        console.error('Error', error instanceof Error ? error.message : error.toString());
    }

    public ignoreKnownErrors(error: any) {
        try {
            if (error instanceof HttpErrorResponse) {
                return (error.error as Object).hasOwnProperty('ErrorMessage') && (LogsOffExceptionList.isErrorOnList(LogsOffExceptionList.exceptionMessageList(), error.error.ErrorMessage));
            }
        } catch (error) {
        }
    }

    public handleError(error: any) {
        console.error('UncaughtExceptionHandler', error);
        if ((!isNullOrUndefined(error.status) && error.status === 401) || this.ignoreKnownErrors(error)) {
            return;
        }

        if (this.router.url.includes('auth') && error.toString() === `TypeError: Cannot read properties of undefined (reading 'nativeElement')`) {
          location.reload();
          return;
        }        

        const errorObj: HandledException = {
            url: undefined,
            referenceRoute: this.router.url,
            body: undefined,
            errorMessage: error.message,
            version: undefined,
            requestId: undefined,
            result: undefined,
            statusCode: undefined,
            stack: error.stack
        };

        if (!isNullOrUndefined(errorObj.referenceRoute) && errorObj.referenceRoute !== '/error') {
            this.errorService.updateMessage(errorObj);
            this.ngZone.run(() => {
                this.router.navigate(['/error']);
            });
        }
    }
}
