export class ExternalTokenModel {
    public token: string;
    public tokenSecret: string;
    public userSocialId: string;
    public userSocialName: string;
    public refreshToken: string;
    public expiredAt: number;
    public provider: string;
    public email: string;
}
