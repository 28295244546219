import { I18N_INFLUENCER_TIMELINE_PT } from './modules/influencer-timeline.i18n';
import { I18N_RELATIONSHIP_TIMELINE_PT } from './modules/relationship-timeline.i18n';
import { I18N_INFLUENCER_PROFILE_PT } from './modules/relationship-profile.i18n';
import { I18N_STEP_INFLUENCER_PT } from './pipes/step-influencer.i18n';
import { I18N_SEARCH_PT } from './modules/search.i18n';
import { I18N_AGE_RANGE_PT } from './modules/age-range.i18n';
import { Injectable } from '@angular/core';
import { I18N_MODAL_CONFIRM_DELETE_MESSAGE_PT } from './modules/modal-confirm-delete.i18n';
import { I18N_INSTAGRAM_PERMISSIONS_PT } from './modules/instagram-permissions.i18n';

@Injectable({
    providedIn: 'root'
})
export class I18nMessagesSelect {
    public get StepInfluencer() {
        return I18N_STEP_INFLUENCER_PT;
    }

    public get InfluencerTimeline() {
        return I18N_INFLUENCER_TIMELINE_PT;
    }

    public get InfluencerProfile() {
        return I18N_INFLUENCER_PROFILE_PT;
    }

    public get RelationshipTimeline() {
        return I18N_RELATIONSHIP_TIMELINE_PT;
    }
    public get Search() {
        return I18N_SEARCH_PT;
    }

    public get AgeRange() {
        return I18N_AGE_RANGE_PT;
    }

    public get ModalConfirmDeleteMessage() {
        return I18N_MODAL_CONFIRM_DELETE_MESSAGE_PT;
    }

    public get InstagramPermissions() {
      return I18N_INSTAGRAM_PERMISSIONS_PT;
    }
}
