import { Injectable } from '@angular/core';
import { ErrorMessage } from 'assets/error-messages/error-message';
import { Message } from 'primeng/api';
import { Observable } from 'rxjs';
import { ExceptionErrorMessagesProvider } from './exception-error-messages.provider';

@Injectable({
    providedIn: 'root'
})
export class ExceptionTranslationProvider {
    constructor(public exceptionErrorMessagesProvider: ExceptionErrorMessagesProvider) {
    }

    public translateErrorMessage(message: Message): Observable<ErrorMessage> {
        return this.exceptionErrorMessagesProvider.getTranslationByKey(message.detail);
    }

    public translateErrorMessages(messages: Message[]): Observable<ErrorMessage[]> {
        return this.exceptionErrorMessagesProvider.getTranslationByKeys(messages.map(x => x.detail));
    }
}
