import { StoreHelper } from '../crosscutting/store-helper';
import { Injectable } from '@angular/core';

@Injectable()
export class LanguageProvider {

    public static getDefaultLanguage(defaultLanguage?: string): string {
        let lang = StoreHelper.lang;
        if (lang === null || lang === undefined || lang === 'undefined') {
            lang = defaultLanguage || 'pt';
            StoreHelper.lang = lang;
        }
        return lang;
    }
    public getDefaultLanguage(defaultLanguage?: string): string {
        return LanguageProvider.getDefaultLanguage(defaultLanguage);
    }
}
