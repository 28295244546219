import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.html',
  styles: []
})
export class EmptyLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}