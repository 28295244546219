import { Component } from '@angular/core';
import { BodyProvider } from '../../../providers/body.provider';
import { AuthProvider } from '../../../providers/auth.provider';
import { Router } from '@angular/router';
import { StoreHelper } from '../../../crosscutting/store-helper';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.html',
  styles: []
})
export class MainLayoutComponent {
  public openAccounManager = false;
  public isAuth = false;
  public menuHeader = 'stepInfluencer';
  public navClick = false;

  constructor(private _bodyProvider: BodyProvider, private _authProvider: AuthProvider, private _router: Router) {
    if (!this._authProvider.isTokenValid(StoreHelper.token)) {
      this._router.navigate(['auth']);
      return;
    }
    this.isAuth = true;
    this._bodyProvider.addCss('main');

    this.menuHeader = (this._router.url === '' || this._router.url.split('/')[1] === '' ? 'home' : this._router.url.split('/')[1]);
  }

  public setMenuMobileOpenOrClose() {
    this.navClick = !this.navClick;
  }
}
