export const I18N_INSTAGRAM_PERMISSIONS_EN = {
  email: 'Receive your e-mail address',
  pages_show_list: 'Shows a list of Pages you manage',
  business_management: 'Manage your business',
  instagram_basic: 'Access the profile and posts of the Instagram account connected to your Page',
  instagram_manage_insights: 'Access to insights from the Instagram account connected to your Page',
  pages_read_engagement: 'Manage a Page\'s accounts, settings, and webhooks',
  pages_manage_metadata: 'Receive notifications of stories insights data',
  public_profile: 'Read public content on the Page'
};

export const I18N_INSTAGRAM_PERMISSIONS_PT = {
  email: 'Receba seu endereço de e-mail',
  pages_show_list: 'Mostrar uma lista das Páginas que você gerencia',
  business_management: 'Gerenciar o seu negócio',
  instagram_basic: 'Acessar o perfil e as publicações da conta do Instagram conectada à sua Página',
  instagram_manage_insights: 'Acesso a insights da conta do Instagram conectada à sua Página',
  pages_read_engagement: 'Gerenciar contas, configurações e webhooks de uma Página',
  pages_manage_metadata: 'Receber notificações dos dados de insights dos stories',
  public_profile: 'Ler o conteúdo publico na Página'
};
