import { Injectable } from '@angular/core';

@Injectable()
export class TestValuesProvider {
    public isNullEmptyOrUndefined(value: any, ...params: any[]): boolean {
        let result = value === undefined || value === null || value === '';
        if (!result) {
            params.forEach((param) => {
                if (value === param) {
                    result = true;
                }
            });
        }
        return result;
    }
}
