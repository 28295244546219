import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ExceptionTranslationProvider } from './exception-translation.provider';
import { Message } from 'primeng/api';
import { ErrorMessage } from 'assets/error-messages/error-message';

@Injectable({
    providedIn: 'root'
})
export class ErrorMessagesService {
    constructor(
        public messageService: MessageService,
        public exceptionTranslationProvider: ExceptionTranslationProvider
    ) {
    }

    public addErrorMessage(message: Message): void {
        this.exceptionTranslationProvider.translateErrorMessage(message).subscribe((errorDictionary) => {
            if (this.showMessage(errorDictionary)) {
                this.messageService.add(errorDictionary);
            }
        });
    }

    public showMessage(errorDictionary: ErrorMessage): boolean {
        return ((!errorDictionary.translated) || errorDictionary.translated);
    }

    public addErrorMessages(messages: Message[]): void {
        this.exceptionTranslationProvider.translateErrorMessages(messages).subscribe((errorDictionaries) => {
            if (errorDictionaries.some((x) => x.translated === false)) {
                errorDictionaries.forEach((errorDictionary) => {
                    if (this.showMessage(errorDictionary)) {
                        this.messageService.add(errorDictionary);
                    }
                });
            } else {
                this.messageService.addAll(errorDictionaries);
            }
        });
    }

    public clearErrorMessages(): void {
        this.messageService.clear();
    }
}
