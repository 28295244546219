import { Injectable, NgZone } from '@angular/core';
import { Url } from '../crosscutting/urls';
import { StoreHelper } from '../crosscutting/store-helper';
import { Router } from '@angular/router';
import { CurrentUser } from '../shared/models/current-user.model';

@Injectable()
export class CurrentUserProvider {
    constructor(private _router: Router, private _url: Url, private _zone: NgZone) {
    }

    public get CurrentUser(): CurrentUser {
        const currentUser = StoreHelper.userInformation;
        if (currentUser === undefined || currentUser === 'undefined' || currentUser === null) {
            this._zone.run(() => {
                this._router.navigate(['/auth/logout']);
            });
        } else {
            return JSON.parse(StoreHelper.userInformation);
        }
    }

    public set CurrentUser(user: CurrentUser) {
      StoreHelper.userInformation = JSON.stringify(user);
    }
}
