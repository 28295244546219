export class CommonApiResponse {
    public ErrorMessage: string;
    public RequestId: string;
    public Result: any;
    public StatusCode: number;
    public Version: string;

    constructor() {
        this.ErrorMessage = undefined;
        this.RequestId = undefined;
        this.Result = undefined;
        this.StatusCode = undefined;
        this.Version = undefined;
    }
}
