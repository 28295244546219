import { Injectable } from '@angular/core';
import { Url } from '../../crosscutting/urls';
import { PagedSearch } from '../../crosscutting/paged-search';
import { HttpClient } from '@angular/common/http';
import { HttpRequestResult } from '../../crosscutting/http-request-result';
import { StoreHelper } from '../../crosscutting/store-helper';

@Injectable()
export class LadderInfluenceService {
    constructor(
        private _http: HttpClient,
        private _url: Url
    ) {
    }

    public getLadderInfluence(customerId: string, callback: any, component: any) {
        this._http.post(this._url.getLadderOfInfluence, JSON.stringify(customerId)).subscribe(
            (response) => { callback(response, component); },
            (error) => { console.log('Error happened' + error); }
        );
    }

    public updateLadder(customerId: string, influencerId: string, ladderStage: string, order: number) {
        this._http.post<HttpRequestResult<void>>(this._url.updateLadderOfInfluence, {
            influencerId,
            stageId: ladderStage,
            customerId,
            index: order
        }).subscribe(
            (response) => {
                if (response.StatusCode !== 200) {
                    console.error(response.ErrorMessage);
                }
            },
            (error) => { console.log('Error happened' + error); }
        );
    }

    public getLadder(callback: any, component: any) {
        this._http.post(this._url.getAllCustomers, { email: StoreHelper.token.username }).subscribe(
            (response) => { callback(response, component); },
            (error) => { console.log('Error happened' + error); }
        );
    }

    public removeInfluencerInLadder(model: any, customerId: string, callback: any, component: any) {
        this._http.request('delete', this._url.removeInfluencerInLadder,
            {
                body: JSON.stringify(
                    {
                        customerId,
                        influencerId: [
                            model.id
                        ]
                    })
            }).subscribe(
                (response) => { callback(response, model, component); },
                (error) => { console.log('Error happened' + error); }
            );
    }

    public insertInfluencersInLadder(customerId: string, influencers: any[], stageId: string, callback: any, component: any) {
        influencers = influencers.filter((f) => f.selected === true);

        const influencersIds: string[] = [];
        influencers.forEach((influencer) => {
            influencersIds.push(influencer.id);
        });

        if (influencersIds.length > 0) {
            this._http.post<HttpRequestResult<any>>(this._url.insertInfluencerInLadderOfInfluence, {
                influencersIds,
                stageId,
                customerId
            }).subscribe(
                (response) => {
                    if (response.StatusCode === 200) {
                        callback(response, component);
                    } else {
                        console.error(response.ErrorMessage);
                    }
                },
                (error) => {
                    console.log('Error happened' + error);
                    callback(error, component);
                }
            );
        }
    }

    public insertNewInfluencersInLadder(influencer: any, callback: any, component: any) {
        this._http.post<HttpRequestResult<any>>(this._url.insertInfluencer, influencer).subscribe(
            (response) => {
                if (response.StatusCode === 200) {
                    callback(response, component);
                } else {
                    console.error(response.ErrorMessage);
                }
            },
            (error) => {
                console.log('Error happened' + error);
                callback(error, component);
            }
        );
    }

    public getInfluencersSuggestions(customerId: string, skip: number, take: number, callback: any, component: any) {
        const params = `?customerId=${customerId}&skip=${skip}&take=${take}`;
        this._http.get(this._url.influencersSuggestion + params).subscribe(
            (response) => { callback(response, component); },
            (error) => { console.log('Error happened' + error); }
        );
    }

    public enableDisableShowSuggestedInfluencer(customerId: string, enable: boolean) {
        const url = enable ? this._url.enableShowSuggestedInfluencer : this._url.disableShowSuggestedInfluencer;
        this._http.post(url, JSON.stringify(customerId)).subscribe(
            (response) => { },
            (error) => { console.log('Error happened' + error); }
        );
    }

    public getAllInfluencersByCustomer(customerId: string, callback: any, component: any) {
        const orderType = '0';
        const orderField = '0';
        this._http.get(this._url.getAllInfluencersByCustomer(customerId, orderType, orderField)).subscribe(
            (response) => { callback(response, component); },
            (error) => { console.log('Error happened' + error); }
        );
    }
}
