import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LoginProvider } from '../../../providers/login.provider';
import { CurrentUserProvider } from '../../../providers/current-user.provider';

@Component({
    selector: 'user-header',
    templateUrl: './user-header.html',
})
export class UserHeaderComponent implements OnInit {
    public userName: string;
    @Output() public onManagerClick = new EventEmitter<boolean>();

    constructor(public service: LoginProvider, private _currentUserProvider: CurrentUserProvider) {
    }

    ngOnInit() {
        this.userName = this._currentUserProvider.CurrentUser?.firstname;
    }

    public logout() {
        this.service.logout();
    }

    public clickManager() {
        this.onManagerClick.emit(true);
    }
}
