export const I18N_INFLUENCER_PROFILE_EN = {
    atividades: 'Activities',
    este_influenciador_nao_esta_em_sua_rede: 'This is not in your network',
    social: 'Social',
};

export const I18N_INFLUENCER_PROFILE_PT = {
    atividades: 'Atividades',
    este_influenciador_nao_esta_em_sua_rede: 'Este influenciador não está em sua rede',
    social: 'Social',
};