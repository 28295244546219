import { ExceptionList } from './exception-list';

export class LogsOffExceptionList extends ExceptionList {
    public static exceptionMessageList(): string[] {
        return [
            'CUSTOMER_NOT_EXISTS',
            'PROXY_FAILED_UNAUTHORIZED',
            'Http failure response for (unknown url)'
        ];
    }
}
