import { Component, OnInit } from '@angular/core';
import { BodyProvider } from '../../../providers/body.provider';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.html',
  styles: []
})
export class AuthLayoutComponent implements OnInit {

  constructor(private _bodyProvider: BodyProvider) {
     this._bodyProvider.removeCss('main');
  }

  ngOnInit() {

  }

}
