export const I18N_AGE_RANGE_EN = {
    CHILDREN: '(0 to 11 years)',
    PRE_TEENAGERS: '(12 to 14 years)',
    TEENAGERS: '(15 to 18 years)',
    YOUNG_ADULT: '(19 to 25 years)',
    ADULT: '(26 to 40 years)',
    MIDDLE_AGE: '(41 to 60 years)',
    ELDERLY: '(61 years and older)'
};

export const I18N_AGE_RANGE_PT = {
    CHILDREN: '(0 a 11 anos)',
    PRE_TEENAGERS: '(12 a 14 anos)',
    TEENAGERS: '(15 a 18 anos)',
    YOUNG_ADULT: '(19 a 25 anos)',
    ADULT: '(26 a 40 anos)',
    MIDDLE_AGE: '(41 a 60 anos)',
    ELDERLY: '(61 anos ou mais)'
};