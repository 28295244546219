import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastModule } from 'primeng/toast';
import { CustomProgressBarComponent } from './charts/components/custom-progress-bar.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { LoadingComponent } from './loading.component';
import { OpeningPhraseComponent } from './opening-phrase.component';
import { ShowErrorComponent } from './show-error/show-error.component';

@NgModule({
    declarations: [
        LoadingComponent,
        ConfirmModalComponent,
        OpeningPhraseComponent,
        ShowErrorComponent,
        CustomProgressBarComponent
    ],
    imports: [ // import Angular's modules
        CommonModule,
        FormsModule,
        ToastModule,
        AlertModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot()
    ],
    providers: [ // expose our Services and Providers into Angular's dependency injection
    ],
    exports: [
        LoadingComponent,
        ConfirmModalComponent,
        OpeningPhraseComponent,
        ShowErrorComponent,
        CustomProgressBarComponent
    ]
})
export class SharedComponentsModule {

}
