export const I18N_MODAL_CONFIRM_DELETE_MESSAGE_EN = {
    'modal-confirm-delete-message': 'Yes, remove.',
    'modal-confirm-delete-message-two': 'Exclude',
    'delete-influencers-from-campaign': 'Do you want to remove these [[COUNT]] campaign influencers?'
};

export const I18N_MODAL_CONFIRM_DELETE_MESSAGE_PT = {
    'modal-confirm-delete-message': 'Sim, excluir!',
    'modal-confirm-delete-message-two': 'Excluir',
    'delete-influencers-from-campaign': 'Deseja remover esses [[COUNT]] influenciadores da campanha?'
};
