export class ErrorMessage {
    public translated: boolean;

    constructor(
        public key: string,
        public summary: string,
        public detail: string
    ) {
    }
}
