export const I18N_RELATIONSHIP_TIMELINE_EN = {
    planejadas: 'Planned',
    passado: 'Past',
    atividades: 'Activities',
    editar: 'Edit',
    deletar: 'Delete',
    date_format: 'MM/dd HH:mm',
    desfazer: 'Undo',
    concluir: 'Close',
    notas: 'Notes',
};

export const I18N_RELATIONSHIP_TIMELINE_PT = {
    planejadas: 'Planejadas',
    passado: 'Passado',
    atividades: 'Atividades',
    editar: 'Editar',
    deletar: 'Deletar',
    date_format: 'dd/MM HH:mm',
    desfazer: 'Desfazer',
    concluir: 'Concluir',
    notas: 'Notas',
};