import { HeadersHelper } from './helpers/headers-helper';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authHeader = HeadersHelper.createHeaders(req.headers);
        const authReq = req.clone({ headers: authHeader });
        return next.handle(authReq);
    }
}
